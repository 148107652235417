import { observable, action, makeObservable } from 'mobx';

// Helpers
import request from '~/bin/httpRequest';

class PaymentStore {
  payMethod = observable.map();

  constructor() {
    makeObservable(this, {
      payMethod: observable,
      CredomaticPonchout: action,
      PonchoutForm: action,
      MPToken: action,
      MPaymentInstruction: action,
      PreCheckout: action.bound,
      Checkout: action.bound
    });
  }

  CredomaticPonchout(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_SERVICES}${process.env.NEXT_PUBLIC_CREDPONCHOUT}`,
      data: query,
    };
    const sessionIdDisable = true;
    return request.genericHandler(options, sessionIdDisable).then((res) => {
      let callback = { action: 'CredomaticPonchout', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.token = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.token = callback;
      }
      return callback;
    });
  }

  PonchoutForm(query) {
    const queryParameters = `hash=${query.hash}&time=${query.time}&ccnumber=${query.ccnumber}&ccexp=${query.ccexp}&amount=${query.amount}&type=${query.type}&orderid=${query.orderid}&key_id=${query.key_id}&cvv=${query.cvv}&redirect=https://clarogt.wcaas.net/checkout/payment`;
    const options = {
      method: 'POST',
      url: `https://credomatic.compassmerchantsolutions.com/api/transact.php?${queryParameters}`,
    };

    const sessionIdDisable = true;
    return request.genericHandler(options, sessionIdDisable).then((res) => {
      let callback = { action: 'PonchoutForm', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.token = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.token = callback;
      }
      return callback;
    });
  }

  MPToken(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_MP_BASE}/card_tokens?public_key=${process.env.NEXT_PUBLIC_MP_KEYS}`,
      data: query,
    };
    const sessionIdDisable = true;
    return request.genericHandler(options, sessionIdDisable).then((res) => {
      let callback = { action: 'MPToken', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.token = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.token = callback;
      }
      return callback;
    });
  }

  async MPaymentInstruction(query, payMethod, route) {
    query.payMethodId = payMethod;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/payment_instruction/`,
      data: query,
    };
    // TODO: Borrar async this.PreCheckout cuando el servicio truena y crear una alerta de error.
    const res = await request.genericHandler(options);
    let callback = { action: 'MPPaymentInstruction', success: false };
    if (!res.error) {
      callback = Object.assign({}, callback, { data: res.data, success: true });
      this.payInstruction = callback;
      callback = await this.PreCheckout(callback.data.data, route);
    } else {
      callback = Object.assign({}, callback, { error: res.error });
      this.payInstruction = callback;
    }
    return callback;
  }

  async PreCheckout(order, route) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/precheckout/`,
    };

    // TODO: Borrar async this.Checkout cuando el servicio truena y crear una alerta de error.
    const res = await request.genericHandler(options);
    let callback = { action: 'PreCheckout', success: false };
    if (!res.error) {
      callback = Object.assign({}, callback, { data: res.data, success: true });
      this.preCheckout = callback;
      callback = await this.Checkout(order, route);
    } else {
      callback = Object.assign({}, callback, { error: res.error });
      this.preCheckout = callback;
    }
    return callback;
  }

  async Checkout(order, route) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/checkout/`,
    };

    const res = await request.genericHandler(options);
    let callback = { action: 'Checkout', success: false };
    if (!res.error) {
      callback = Object.assign({}, callback, { data: res.data, success: true });
      this.checkout = callback;
      route.history.push(`/checkout/confirm?oid=${order.external_referer}`);
    } else {
      callback = Object.assign({}, callback, { error: res.error });
      this.checkout = callback;
    }
    return callback;
  }
}

export default new PaymentStore();
